
/* SCSS HEX */
$white: #ffffff;
$gunmetal: #151517;
$gunmetal_2: #232326;
$gunmetal_3: #303034;

$gunmetal_transparent: rgba(13, 23, 27, 0.5);
$gunmetal_transparent_1: rgba(13, 23, 27, 0.1);
$gunmetal_transparent_2: rgba(13, 23, 27, 0.2);
$gunmetal_transparent_3: rgba(13, 23, 27, 0.3);
$gunmetal_transparent_4: rgba(13, 23, 27, 0.4);
$silver: #C4C4C4;
$chartreuse: #d2f22f;
$chartreuse_transparent: rgba(210, 242, 47, 0.1);
$asparagus: #66996C;
$light-gray: #F9F9F9;

// $white: #ffffff;
// $gunmetal: #151517;
// $gunmetal_transparent: rgba(33, 37, 31, 0.5);
// $silver: #C4C4C4;
// $chartreuse: #c8f560;
// // $chartreuse_transparent: rgba(200, 245, 96, 0.1);
// $asparagus: #66996C;
// $light-gray: #F9F9F9;

// bootstrap merge with existing $theme-colors map
$primary: $chartreuse;
$light: $silver;
$dark: $gunmetal;
$enable-negative-margins: true;