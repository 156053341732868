.flip-card {
   background-color: transparent;
   width: 100%;
   height: 250px;
   perspective: 1000px;
   margin-bottom: 2rem;
 }
 
 .flip-card-inner {
   position: relative;
   width: 100%;
   height: 100%;
   text-align: center;
   transition: transform 0.3s cubic-bezier(0.75, 0, 0.85, 1);
   transform-style: preserve-3d;
 }
 
 .flip-card:hover .flip-card-inner {
   transform: rotateY(180deg);
 }
 
 .flip-card-front, .flip-card-back {
   position: absolute;
   width: 100%;
   height: 100%;
   -webkit-backface-visibility: hidden;
   backface-visibility: hidden;
 }
 
 .flip-card-front {
   background: $white;
   border-bottom: 10px solid $gunmetal;
 }
 
 .flip-card-back {
   background: $gunmetal;
   transform: rotateY(180deg);
   
   color: $white;
   border-bottom: 10px solid $chartreuse;
 
 }
 
 .flip-card-content {
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;

   i {
      font-size: 3rem;
   }
 }